import { connect } from 'react-redux'

import Existing from './existing'

import {
  nextStep,
  submitData,
  toggleBypassZip,
  toggleIncludeAddress,
} from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitData,
  toggleBypassZip,
  toggleIncludeAddress,
})(Existing)
